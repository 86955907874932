import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby";

import pam from "../images/pam-banner.jpg"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import '../components/font-awesome';
import { Accordion, Card } from "react-bootstrap"
import CourseForm from "../components/course-form"

import Layout from "../components/layout"
import SEO from "../components/seo"
import LogosSection from "../components/logosSection";

const webcentrix={
  Level:'', 
  academicUnit: 'ICDA', 
  career: 'pam'
}

const organizations = [
  {
    name: "AACREA (CREA)"
  },
  {
    name: "Agross Tecnología Agrícola"
  },
  {
    name: "Aguas Cordobesas"
  },
  {
    name: "APEX América"
  },
  {
    name: "Autocity"
  },
  {
    name: "AVIABUE - Asociación de Agencias de Viajes y Turismo de Buenos Aires"
  },
  {
    name: "Cablevisión Fibertel"
  },
  {
    name: "Cámara de Industriales Metalúrgicos y de Componentes de Córdoba"
  },
  {
    name: "Claro"
  },
  {
    name: "Clínica Universitaria Reina Fabiola"
  },
  {
    name: "Coca Cola Andina Argentina"
  },
  {
    name: "CREA Barranca Yaco"
  },
  {
    name: "Dracma"
  },
  {
    name: "Ecogas"
  },
  {
    name: "FIAT"
  },
  {
    name: "Fiat Services"
  },
  {
    name: "Grupo Agroempresa Argentina"
  },
  {
    name: "Groupe Roullier"
  },
  {
    name: "Grupo Cargo"
  },
  {
    name: "Grupo OSDE"
  },
  {
    name: "Grupo Roggio"
  },
  {
    name: "Holcim Argentina"
  },
  {
    name: "Kolektor"
  },
  {
    name: "Intesar - Integración Eléctrica Sur Argentina S.A. - Grupo Eling"
  },
  {
    name: "Iveco"
  },
  {
    name: "Jumbo - Cencosud"
  },
  {
    name: "La Voz del Interior"
  },
  {
    name: "Ministerio de Administración y Gestión Pública de la Provincia de Córdoba"
  },
  {
    name: "Ministerio de Industria, Comercio y Minería de la Provincia de Córdoba"
  },
  {
    name: "Mundo Maipú"
  },
  {
    name: "Sancor Seguros"
  },
  {
    name: "Sociedad Rural de Jesús María"
  },
  {
    name: "Urgencias"
  },
  {
    name: "V/N Business Process Outsourcing"
  },
  {
    name: "Volkswagen Argentina"
  },
  {
    name: "Coca Cola Andina Chile",
    foreign: true
  },
]

const courses = [
  {
    name: "Desarrollando el Potencial Innovador. Programa para Alta Gerencia"
  },
  {
    name: "Programa en Formación ejecutiva para mandos medios"
  },
  {
    name: "Management en transformación digital"
  },
  {
    name: "Innovation Management Program (IMP)"
  },
  {
    name: "Programa de Dirección de Empresas Agropecuarias"
  },
  {
    name: "Programa en Gestión Administrativa de la Empresa Agropecuaria"
  },
  {
    name: "Herramientas de Gestión para Lideres y Equipos"
  },
  {
    name: "Diplomatura Skeam (Skills + Team) para Líderes Ejecutivos. Rindo - ICDA"
  },
  {
    name: "Certificado Skeam (Skills + Team) para Líderes. Rindo - ICDA"
  },
  {
    name: "Liderazgo y Soft Skills - Consultoría y capacitación"
  },
  {
    name: "Open innovation"
  },
  {
    name: "Liderazgo Social"
  },
  {
    name: "Programa de formación internacional en cooperativismo del siglo XXI. Universidad de Mondragón"
  },
  {
    name: "MOC: Microeconomía de la Competitividad"
  },
  {
    name: "Diplomatura en Gestión Participativa y Descentralización Municipal"
  },
  {
    name: "Innovación, Transparencia y Sustentabilidad en Compras Públicas"
  },
  {
    name: "Diplomatura en Políticas Públicas para el Desarrollo Local y Regional"
  },
  {
    name: "Programa Ejecutivo de Formación en Corredores Bioceánicos e Integración Regional"
  },
  {
    name: "Programa Ejecutivo en Innovación, Transparencia y Sustentabilidad en Compras Públicas"
  },
  {
    name: "Innovation y soft skills workshops"
  },
  {
    name: "Liderazgo Haka"
  },
  {
    name: "Líder Gestor del Cambio"
  },
  {
    name: "Líderes Mentores"
  },
  {
    name: "Inversores Ángeles"
  },
  {
    name: "Metodologías ágiles"
  },
  {
    name: "Media training"
  },
  {
    name: "Vender lo digital"
  },
  {
    name: "Customer experience"
  },
  {
    name: "Finanzas para no financieros"
  },
  {
    name: "Calidad en el servicio"
  },
  {
    name: "Coaching ontológico"
  },
  {
    name: "Jornadas de Finanzas Personales"
  },
  {
    name: "Curso en Administración de Finanzas Personales"
  },
  {
    name: "Workshop en Storytelling y presentaciones de alto impacto"
  },
  {
    name: "Acuerdos sustentables en el tiempo"
  },
  {
    name: "Taller de Operaciones y Logística"
  },
  {
    name: "Rally de innovación"
  },
]


const PamPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "formacion-corporativa" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const logos = data.allFile.edges.map(({ node }) => ({
    name: node.name,
    img: {
      url: node.childImageSharp.fluid.src
    }
  }))

  const logosSectionContent = {
    title: "Organizaciones a las que acompañamos",
    summary: "",
    media: logos
  }

  return (
  <Layout>
    <SEO title="Formación Corporativa - ICDA" />

    <section className="mb-4">
      <div className="w-100 d-flex bg-light inst-img mb-4" style={{ backgroundImage: `url(${pam})`, minHeight: "55vh" }}>
        <div className="w-100 flex-grow-1 d-flex flex-column align-items-center justify-content-center text-center text-white p-5 background">
          <h2 className="mb-4 fw-bold text-uppercase title">
            Formación corporativa
          </h2>
          <div className="btn-cont d-flex align-items-center justify-content-center flex-wrap">
            <Link to={"https://api.whatsapp.com/send?phone=5493513118735"} className="btn btn-outline-light m-2" target="_blank">
              CONVERSÁ CON NOSOTROS
            </Link>
            <a href={"mailto:incompany.icda@ucc.edu.ar"} className="btn btn-outline-light m-2" target="_blank">
              ESCRIBINOS AL MAIL
            </a>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="my-5">
          <h4 style={{color: "#1d317d", fontWeight: "900"}}>
            PROPUESTA DE VALOR
          </h4>
          <p>
            Los programas a medida son propuestas llevadas a cabo por nuestra área de capacitación in company, diseñadas para dar respuesta a demandas específicas de generación, desarrollo y gestión del talento en una organización, ya sea ésta privada, pública o de la sociedad civil.
          </p>
        </div>

        <div className="my-5">
          <LogosSection data={logosSectionContent}/>

          {/* <h4 className="font-weight-bold mt-5">
            Conocé cada organización
          </h4> */}
          <Accordion defaultActiveKey="">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="0">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 accordion-title cursor-pointer">
                    <h6 className="mb-0">
                      Argentina
                    </h6>
                  </div>
                  <div className="mr-2">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <div className="row">
                    {organizations.map((org) => {
                      return (
                        <div className="col-12 col-md-6">
                          <div className={`d-flex align-items-center my-2 ${!org.foreign ? "text-primary": "text-secondary"}`}>
                              <h6  className="mb-0 mr-2 font-weight-bold">-</h6>
                              <h6 className="mb-0">
                                {org.name}
                              </h6>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            {/* <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 accordion-title cursor-pointer">
                    <h6 className="mb-0">
                      Extranjero
                    </h6>
                  </div>
                  <div className="mr-2">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="d-flex align-items-center my-2 text-primary">
                        <h6 className="mb-0 mr-2 font-weight-bold">-</h6>
                        <h6 className="mb-0">
                          Coca Cola Andina Chile
                        </h6>
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}

            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="2">
                <div className="d-flex align-items-center">
                  <div className="flex-grow-1 accordion-title cursor-pointer">
                    <h6 className="mb-0">
                      Cursos
                    </h6>
                  </div>
                  <div className="mr-2">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </div>
              </Accordion.Toggle>

              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <div className="row">
                    {courses.map((course) => {
                      return (
                        <div className="col-12 col-md-6">
                          <div className={`d-flex align-items-center my-2 text-primary`}>
                              <h6  className="mb-0 mr-2 font-weight-bold">-</h6>
                              <h6 className="mb-0">
                                {course.name}
                              </h6>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>

        <div className="my-5">
          <h4 className="font-weight-bold">
            MODALIDADES
          </h4>
          <p>
            Dependiendo de los requermientos particulares de desarrollo de talento de cada organización, los prorgramas de capacitación a medida pueden llevarse a cabo a través de alguna de las siguientes modalidades:
          </p>
          <div className="mb-3 bg-light px-4 py-2">
            <div className="my-2 py-2 border-bottom">
              <h6 className="text-sec mb-2">
                UNIVERSIDAD CORPORATIVA
              </h6>
              <p>
                Se concibe como un proceso de educación continua a través del cual las empresas gestionan el conocimiento, integran, promueven y garantizan una formación estratégica para sus recursos humanos. Cada año esta capacitación se va adaptando a la realidad vigente de la organización que la realiza.
              </p>
            </div>
            <div className="my-2 py-2 border-bottom">
              <h6 className="text-sec mb-2">
                PROGRAMAS EJECUTIVOS
              </h6>
              <p>
                Propuestas codiseñadas entre el ICDA y la organización, que permiten en poco tiempo desarrollar habilidades y competencias específicas en alguna de las áreas funcionales de la gestión organizacional: Marketing, Finanzas, Recursos Humanos, Logística, Responsabilidad Social, Liderazgo, entre otras
              </p>
            </div>
            <div className="my-2 py-2 border-bottom">
              <h6 className="text-sec mb-2">
                CURSOS DE POSGRADO
              </h6>
              <p>
                Son programas de mediana duración para colaboradores que cuenten con formación de grado previa. La certificación del ICDA es acompañada por un legajo UCC.
              </p>
            </div>
            <div className="my-2 py-2 border-bottom">
              <h6 className="text-sec mb-2">
                ENTRENAMIENTOS INTENSIVOS
              </h6>
              <p>
                Talleres de corta duración concebidos en un ambiente participativo y de comunicación abierta, con dinámicas prácticas para alcanzar los objetivos de forma inmediata, trabajar y resolver situaciones específicas.
              </p>
            </div>
            <div className="my-2 py-2 border-bottom">
              <h6 className="text-sec mb-2">
                OUTDOORS EXPERIENCIALES
              </h6>
              <p>
                Jornadas de trabajo al aire libre, con actividades lúdicas y de simulación coordinadas por facilitadores especializados en comunicación, trabajo en equipo y liderazgo.
              </p>
            </div>
            <div className="my-2 py-2">
              <h6 className="text-sec mb-2">
                JORNADAS ESPECIALIZADAS
              </h6>
              <p>
                Seminarios, conferencias o desayunos de trabajo diseñados para un área o grupo de personas determinado de la organización. Estas actividades están a cargo de reconocidos docentes y consultores con expertise en diferentes temáticas de la realidad actual.
              </p>
            </div>
          </div>
        </div>
        <div className="my-5">
          <CourseForm webcentrix={webcentrix}/>
        </div>
      </div>
    </section>
  </Layout>
  )
}


export default PamPage

